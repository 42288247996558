import { Typography } from '@fdha/web-ui-library';
import { MultipleChoiceQuestionProps } from '@models';
import { Checkbox, FormControlLabel, Stack, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface MultipleChoiceViewProps {
  question: MultipleChoiceQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

export const MultipleChoiceView: FC<MultipleChoiceViewProps> = ({
  question,
  handleChange,
}) => {
  const { values } = useFormikContext<any>();
  const theme = useTheme();

  const key = question.id;
  const array = values[key] || [];

  return (
    <Stack gap={1}>
      {question.options.map((option) => (
        <FormControlLabel
          key={option.value}
          label={
            <Typography i18nKey={option?.i18nKey} alignItems="flex-end">
              {option.label}
            </Typography>
          }
          checked={array.includes(option.value)}
          control={
            <Checkbox
              sx={{
                '&, &.Mui-checked': {
                  color: theme.palette.info.dark,
                },
              }}
            />
          }
          onChange={(_event, value) => {
            if (value) {
              handleChange([...array, option.value]);
            } else {
              array.splice(array.indexOf(option.value), 1);
              handleChange(array);
            }
          }}
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: 2,
            borderRadius: '8px',
            border: `${
              array.includes(option.value)
                ? `2px solid ${theme.palette.info.dark}`
                : `0.5px solid ${theme.palette.action.disabled}`
            }`,
            '&:hover': {
              backgroundColor: theme.palette.action.background,
            },
          }}
        />
      ))}
    </Stack>
  );
};
