import { Icon, TextFieldV2, Typography } from '@fdha/web-ui-library';
import { BinaryQuestionProps, SingleChoiceQuestionProps } from '@models';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface SingleChoiceViewProps {
  question: SingleChoiceQuestionProps | BinaryQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

interface SingleChoiceValue {
  option: string;
  otherOption: string;
}

interface Option {
  label: string;
  value: string;
  i18nKey?: string;
  icon?: string;
}

interface Context {
  [key: string]: SingleChoiceValue;
}

export const SingleChoiceView: FC<SingleChoiceViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, errors, touched } = useFormikContext<Context>();

  const theme = useTheme();

  const getRadioIcon = (option: Option) => {
    if ('icon' in option && option.icon) {
      const { icon } = option;
      return <Icon name={icon} fill={theme.palette.info.dark} size="large" />;
    }

    return undefined;
  };

  const key = question.id;
  const showOtherOption = values[key].option === 'other';
  const error = touched[key]?.otherOption && errors[key]?.otherOption;

  return (
    <Stack spacing={2}>
      <FormControl>
        <RadioGroup
          data-testid="SINGLE_CHOICE_RADIO"
          key={key}
          value={values[key].option}
          onChange={(event) => {
            handleChange({ option: event.target.value, otherOption: '' });
          }}
          sx={{ gap: 1 }}
        >
          {question.options.map((option) => (
            <FormControlLabel
              data-testid="SINGLE_CHOICE_RADIO_OPTION"
              key={option.value}
              label={
                <Typography i18nKey={option?.i18nKey}>
                  {option.label}
                </Typography>
              }
              value={option.value}
              control={
                <Radio
                  icon={getRadioIcon(option)}
                  checkedIcon={getRadioIcon(option)}
                  sx={{
                    '&, &.Mui-checked': {
                      color: theme.palette.info.dark,
                    },
                  }}
                />
              }
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: 2,
                borderRadius: '8px',
                border: `${
                  values[key].option === option.value
                    ? `2px solid ${theme.palette.info.dark}`
                    : `0.5px solid ${theme.palette.action.disabled}`
                }`,
                '&:hover': {
                  backgroundColor: theme.palette.action.background,
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {showOtherOption && (
        <TextFieldV2
          value={values[key].otherOption}
          onChange={(event) =>
            handleChange({
              option: values[key].option,
              otherOption: event.target.value,
            })
          }
          error={!!error}
          helperText={error}
        />
      )}
    </Stack>
  );
};
