import {
  ContentExplanation,
  Maybe,
  ScheduledItemType,
  SurveyFrequency,
  SurveyInstance,
  SurveyQuestionType,
  WeekDay,
} from '@fdha/graphql-api-patient';
import { QuestionProps } from '@models';
import { format, utcToZonedTime } from 'date-fns-tz';

import { getBHBQuestion } from './dataSurveyUtils';

interface RecurrenceOpts {
  frequency?: SurveyFrequency;
  dueAt?: string;
  weekDays?: WeekDay[];
}

type SurveyViewType = 'explanation' | 'question' | 'confirmation';

export type ExplanationViewProps = {
  id: string;
  title: string;
  i18nKeyTitle?: string;
  headerAsset: string;
  content?: Maybe<ContentExplanation>[];
  i18nKeyContent?: string[];
};

type QuestionViewProps = QuestionProps;

type ConfirmationViewProps = { id: string };

interface SurveyViewBase {
  type: SurveyViewType;
  showTitle: boolean;
  showDate: boolean;
  showProgress: boolean;
  hideProgressLabel?: boolean;
}

interface SurveyViewExplanation extends SurveyViewBase {
  type: 'explanation';
  viewProps: ExplanationViewProps;
}

export interface SurveyViewQuestion extends SurveyViewBase {
  type: 'question';
  viewProps: QuestionViewProps;
}

interface SurveyViewConfirmation extends SurveyViewBase {
  type: 'confirmation';
  viewProps: ConfirmationViewProps;
}

export type SurveyView =
  | SurveyViewExplanation
  | SurveyViewQuestion
  | SurveyViewConfirmation;

export const getRecurrenceText = (
  { frequency, dueAt = '', weekDays = [] }: RecurrenceOpts,
  translate: (
    key: string,
    defaultValue: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  if (!frequency) {
    return { i18nKey: '', i18nParams: undefined, formattedString: '' };
  }

  const timestamp = new Date(Number(dueAt));

  const date = format(utcToZonedTime(timestamp, 'UTC'), 'MMM dd');
  const day = format(utcToZonedTime(timestamp, 'UTC'), 'd');
  const hour = format(utcToZonedTime(timestamp, 'UTC'), 'hh:mm a');

  const getFormattedWeekDays = () => {
    return weekDays
      .map((d) =>
        translate(
          `common:weekDays.${d.toLocaleLowerCase()}`,
          d.toLocaleLowerCase()
        )
      )
      .join(', ');
  };

  switch (frequency) {
    case SurveyFrequency.Once:
      if (dueAt) {
        return {
          i18nKey: 'surveys:recurrence.onceWithDate',
          i18nParams: { date: timestamp, hour },
          formattedString: `${frequency} on ${date} at ${hour}`,
        };
      }
      // Welcome survey must not have a due_at value
      return {
        i18nKey: 'surveys:recurrence.once',
        i18nParams: undefined,
        formattedString: frequency,
      };
    case SurveyFrequency.Daily:
      return {
        i18nKey: 'surveys:recurrence.daily',
        i18nParams: { hour },
        formattedString: `${frequency} at ${hour}`,
      };
    case SurveyFrequency.Weekly:
    case SurveyFrequency.Biweekly:
    case SurveyFrequency.Triweekly:
      return {
        i18nKey: 'surveys:recurrence.' + frequency.toLowerCase(),
        i18nParams: { weekDays: getFormattedWeekDays(), hour },
        formattedString: `${frequency} on ${getFormattedWeekDays()} at ${hour}`,
      };
    case SurveyFrequency.Monthly:
      return {
        i18nKey: 'surveys:recurrence.monthly',
        i18nParams: { day: timestamp, hour },
        formattedString: `${frequency} on ${day} at ${hour}`,
      };
    case SurveyFrequency.DaysOnDiet:
      return {
        i18nKey: 'temporary:surveys.recurrence.daysondiet',
        i18nParams: { weekDays: getFormattedWeekDays(), hour },
        formattedString: `Days on diet: ${getFormattedWeekDays()} at ${hour}`,
      };
    default:
      return { i18nKey: '', i18nParams: undefined, formattedString: '' };
  }
};

const mapPossibleAnswers = (
  possibleAnswers?: string[] | null,
  possibleAnswersIcons?: string[] | null
) => {
  return possibleAnswers?.map((option, index) => ({
    value: option,
    label: option,
    icon: possibleAnswersIcons?.[index],
  }));
};

export const buildSurveyViews = (
  survey: SurveyInstance
): SurveyView[] | undefined => {
  let views: SurveyView[] = [];

  if (survey.instanceType === ScheduledItemType.Bhb) {
    views.push({
      type: 'question',
      showTitle: true,
      showDate: true,
      showProgress: true,
      hideProgressLabel: true,
      viewProps: getBHBQuestion(survey.due_at),
    });
  }

  survey.questions?.forEach((question) => {
    if (question.type === SurveyQuestionType.Explanation) {
      views.push({
        type: 'explanation',
        showTitle: false,
        showDate: false,
        showProgress: false,
        viewProps: {
          id: question.id,
          title: question.title,
          headerAsset:
            (question?.customPropsOfType as ExplanationViewProps)
              ?.headerAsset || '',
          content:
            (question?.customPropsOfType as ExplanationViewProps)?.content ||
            [],
        },
      });
    } else {
      views.push({
        type: 'question',
        showTitle: true,
        showDate: true,
        showProgress: true,
        viewProps: {
          ...question,
          subtitle: question.helpText,
          placeholder: question.placeholderText || '',
          options: mapPossibleAnswers(
            question.possibleAnswers,
            question.possibleAnswersIcons
          ),
        } as any,
      });
    }
  });

  if (survey.requires_confirmation) {
    views.push({
      type: 'confirmation',
      showTitle: true,
      showDate: true,
      showProgress: true,
      viewProps: { id: 'confirmation' },
    });
  }

  return views;
};
