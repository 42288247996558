import { TextFieldV2 } from '@fdha/web-ui-library';
import { OpenTextQuestionProps } from '@models';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface OpenTextViewProps {
  question: OpenTextQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

interface Context {
  [key: string]: string;
}

export const OpenTextView: FC<OpenTextViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, handleBlur, errors, touched } = useFormikContext<Context>();

  const theme = useTheme();

  const key = question.id;
  const error = touched[key] ? errors[key] : '';

  return (
    <TextFieldV2
      multiline
      name={key}
      value={values[key]}
      placeholder={question.placeholder}
      onChange={(event) => handleChange(event.target.value)}
      onBlur={handleBlur}
      inputProps={{ maxLength: question.maxLength }}
      error={!!error}
      helperText={error || question.helperText}
      i18nKeyHelper={question?.i18nKeyHelperText}
      i18nKeyPlaceholder={question.i18nKeyPlaceholder}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
      }}
    />
  );
};
